.Followup{
.follow-up{
	&::before{
		z-index: -1;
		opacity: 0.3;
		content: "";
		position: fixed;
		height: 100%;
		width: 100%;
		background: #ffffff !important;
		background: -moz-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
		background: -webkit-gradient(left top, left bottom, color-stop(0%, #67c4b1), color-stop(24%, #fff), color-stop(49%, #fff), color-stop(69%, #fff), color-stop(100%, #5cc0ad)) !important;
		background: -webkit-linear-gradient(top, #257eba 0%, #fff 24%, #fff 49%, #fff 69%, #257eba 100%) !important;
		background: -o-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
		background: -ms-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
		background: linear-gradient(to bottom, #257eba 0%, #fff 24%, #fff 49%, #fff 69%, #257eba 100%) !important;
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#67c4b1', endColorstr='#5cc0ad', GradientType=0 );
	}
	.container{
		max-width: 600px !important;
	}
}

.followup-inner{
	&::after{
	  z-index: -1;
	  content: "";
	  position: fixed;
	  height: 153px;
	  width: 100%;
	  top:8%;
	  background-image: url(../img/logo.png) !important;
	  background-position:center !important;
	  background-repeat: no-repeat!important; 
	  opacity: 0.28;
	  background-size: 13%;
  }
  .data-div{
	  padding: 0px 0 !important;
	  background: #fff;
	  margin: 80px 0 0px !important;
	  -moz-border-radius: 5px;
	  -webkit-border-radius: 5px;
	  border-radius: 5px;
  }
  .blue-bg{
	  background: #257eba;
	  -moz-border-radius: 5px;
	  -webkit-border-radius: 5px;
	  border-radius: 5px;
	  p{
		  font-size: 15px;
		  text-align: center;
		  padding:7px 10PX;
		  color: #fff;
	  }
  }

  	.table{

		th{ padding: 10px !important; 
			vertical-align: middle !important; 
			color: #495057;
			background-color: #e9ecef;
			border-color: #dee2e6;
		}
		td{ 
			padding: 10px !important;    
			vertical-align: middle !important;
		}
	}


  .btn-submit{
	  background: #359f14 !important;
	  color: #fff !important;
	  padding: 12px 10px !important;
	  border: 0px;
	  border-bottom: solid 4px #1e7b01 !important;
	  -moz-border-radius: 5px;
	  -webkit-border-radius: 5px;
	  border-radius: 5px;
	  width: 100% !important;
	  margin-top: 15px;
	  text-transform: uppercase;
	  bottom: 0;
	  position: fixed;
	  left: 0;
  }
  .space{
	  margin: 45% 0 !important;
	  img{
		  width: 100px; 
		  margin-top: 30px;
	  }
  }
  .slide2{
	  margin: 20px 0 0;
  }
  h3{
	  font-size: 20px;
	  text-align: center;
	  padding: 10PX;
	  color: #213366;
  }

  h4{
	  font-size: 19px !important;
	  text-align: center;
	  padding: 10PX;
	  color: #fff !important;
	  margin-bottom: 0px !important;
	  padding-bottom: 0px !important;
	  strong{
		  font-weight: 700;
	  }
  }
  .form-control{	
	  background-color:#fff;
	  border: 2px solid #d5d5d5;
	  font-size: 16px;
	  font-weight: normal;
	  height: 50px !important;
	  outline: none !important;
	  padding: 0px 10px;
	  
  }
}

.signature_canvaseWrap .signature-pad {
    border: 1px dotted #000!important;
    background: url(../img/sign-bg.png) no-repeat #fff 49% 67px!important;
    border-radius: 5px;
    display: block;
    margin: 20px auto;
    position: relative;
    width: 90%;
    height: 250px;
}
.sign_msg {
	color: #ffffff;
	width: 100%;
	float: left;
	background: #f00;
	font-size: 13px;
	padding: 0px 5px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	margin-bottom: 10px;
	text-align:center;
}

.modal-content .modal-header {
    background: #257eba;
    border-radius: none !important;
}

.modal-content .modal-header .modal-title {
    color: #fff;
    font-size: 18px;
}

.cannot_accept{
	position: absolute;
    top: 40px;
    background: #efefef;
    padding: 34px 10px 34px;
    width: 94%;
    border: 1px solid #000;
    border-radius: 5px;

	p{
		font-weight: 600;
		color: #ff0000;
		margin-bottom: 15px;
	}
}


.sign-sec{
	ul{
		margin: 0px;
		padding: 0 0px;
		li{
			background: url(../img/download.png) no-repeat left center;
			list-style: none;
			padding:5px 0 5px 35px;
			margin-bottom: 0px;
			font-size: 15px;
		}
	}
	.t-area{
		min-height: 200px;
	}
	.sign-div{
		padding: 20px 0 0;
		.sign-text{
			position: absolute;
			z-index: 999;
			font-size: 17px;
			color: #5f5f5f;
			width: 94.5%;
			img{
				margin-top:100px;
			}
		}
	}
	
}

.form-select{
	height: 48px;
}

.btn-next {
    background: #257eba;
    color: #fff;
    font-weight: 400;
    font-size: 20px;
    padding: 15px 10px;
    width: 100%;
    cursor: pointer;
    border: 0;
    border-radius: 5px !important;
    margin: 15px 0;
}

.btn-next-pop{
	background: #359f14 !important;
	color: #fff !important;
	padding: 12px 10px !important;
	border: 0px;
	border-bottom: solid 4px #1e7b01 !important;
	-moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
	width: 100% !important;
	margin-top: 15px;
	text-transform: uppercase;
	bottom: 0;
	position: fixed;
	left: 0;
	z-index: 9999;
}
.add-btn{
	background:none;
	padding:5px 10px;
	color: red;
	font-size: 14px;
	font-weight: normal;
	border: 2px solid red;
	border-radius: 5px;
	font-style: italic;
	min-width: 90px;
}

.error{
	display: none;
    color: #fff;
    background: #f00;
    width: 100%;
    padding: 2px 5px;
    float: left;
    text-align: center;
    border-radius: 4px;
    font-size: 13px;
}

.btn-clear{
	background-color:red;
	color: #fff!important;
	font-size: 15px !important;
	border-radius: 4px;
	padding: 9px 15px;
	border:none;
	width:auto;
	cursor: pointer;
	margin-bottom: 10px;
	text-transform: uppercase;
	&:hover{
		background-color: #213366;
	}
}


.submitpop{
	background-color: #359f14;
	color: #fff !important;
	font-size: 15px !important;
	border-radius: 4px;
	padding: 9px 10px;
	border: none;
	width:auto;
	cursor: pointer;
	margin-bottom: 10px;
	text-transform: uppercase;
	float: right;
	text-align: center;
	&:hover{
		background-color: #213366;
	}
}
.hide{
	display: none;
}
.show{
	display: block;
}

.next02{
	background-color: #359f14;
	color: #fff!important;
	font-size: 15px !important;
	border-radius:4px;
	padding: 9px 20px !important;
	border: none;
	width:100%;
	cursor: pointer;
	margin-bottom: 10px;
	text-transform: uppercase;
	float: right;
	text-align: center;
	&:hover{
		background-color: #213366;
	}
}



.signature-modal{
	padding-right: 0px;
	.modal-content{
		border:0px !important;
		margin-top:20%;
		h4{
			font-size: 20px;
			color: #213366;
		}
		.modal-header{
			background: #257eba;
			border-radius: none !important;
			.modal-title{
				color: #fff;
				font-size: 18px;
			}
		}
		.modal-body{
			h2{
				color: #2f3996 !important;
				font-size:18px !important;
				font-weight: 600;
				margin-bottom: 20px;
			}
		}

		.modal-footer{
			background: #fff;
			border: none;
			padding: 10px 20px !important;
		}

		h3{
			font-size: 18px;
			color: #000;
		}
		p{
			font-size: 14px;
			margin-bottom: 7px;
		}
	}

}
@-webkit-keyframes fadeIn {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
  }
  @keyframes fadeIn {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
  }
  
  .fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
  }
  
  @-webkit-keyframes fadeInDown {
	from {
	  opacity: 0;
	  -webkit-transform: translate3d(0, -100%, 0);
	  transform: translate3d(0, -100%, 0);
	}
	to {
	  opacity: 1;
	  -webkit-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	}
  }
  
  @keyframes fadeInDown {
	from {
	  opacity: 0;
	  -webkit-transform: translate3d(0, -100%, 0);
	  transform: translate3d(0, -100%, 0);
	}
	to {
	  opacity: 1;
	  -webkit-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	}
  }
  
  .fadeInDown {
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
  }
  
  .animated {
	-webkit-animation-duration: 3s;
	animation-duration: 3s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
  }
  
  @keyframes fadeInUp {
	from {
	  transform: translate3d(0, 40px, 0);
	  -webkit-transition: -webkit-transform 2.6s ease-in-out;
	  transition: transform 0.6s ease-in-out;
	}
  
	to {
	  transform: translate3d(0, 0, 0);
	  opacity: 1;
	}
  }
  
  @-webkit-keyframes fadeInUp {
	from {
	  transform: translate3d(0, 40px, 0);
	}
  
	to {
	  transform: translate3d(0, 0, 0);
	  opacity: 1;
	}
  }
  .fadeInUp {
	opacity: 0;
	animation-name: fadeInUp;
	-webkit-animation-name: fadeInUp;
  }
  
  
.animated-effect {
	-webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
	-moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
	-ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
	animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
	box-shadow: 0 0 0 0 #359f14;
}


@media screen and (max-width:1700px) and (min-width:992px) {
	.followup-inner{
		&::after{
			background-size: 15%;
		}
	}
}

@media screen and (max-width:992px) and (min-width: 768px) {
	.followup-inner{
		&::after{
			background-size: 15%;
		}
	}
}

@media screen and (max-width: 767px) {
.followup-inner{
	&::after{
		background-size: 40%;
	}
}

.data-div{
	padding: 0px 0 0px !important;
	margin: 60px 0 15px !important;
}
.table{
	th{ padding: 10px !important; 
		vertical-align: middle !important; 
	}


	td{ padding: 10px !important;  
		vertical-align: middle !important;
	}
}

}


@media (max-width: 576px) {
	.followup-inner{
		&::after{
			background-size: 40%;
			top:7%;
		}
	}
	.cannot_accept{
		width: 90%;
		padding: 40px 10px;
		top: 10px;
	}
}
}



