
.TMS_BRN_V3_2_1B{
	header {
		padding: 0 0 75px;
	
		.logo {
		  height: 60px;
		}
	
		h1 {
		  color: #fff;
		  font-size: 38px;
		  font-weight: 600;
		  padding-top: 20px;
		}
	
		h2 {
		  color: #fff;
		  font-size: 23px;
		  font-weight: 400;
		}
	
		h3 {
		  color: #fff;
		  font-size: 20px;
		  font-weight: 400;
		}
	
		h6 {
		  color: #fff;
		  font-size: 16px;
		  font-weight: 400;
		}
	  }
	
	  .form-section {
		form {
		  .slidetop_adjust {
			position: relative;
			margin-top: -56px;
		  }
	
		  h4 {
			margin: 0 !important;
			padding: 0 0 20px;
			font-size: 20px !important;
			color: #777;
	
			.txt-highlite {
			  color: #f7a900;
			}
		  }
	
		  h6 {
			font-size: 18px !important;
			color: #777;
		  }
	
		  .radio_buttons {
			.radio {
			  background: #2f9de8;
			  display: inline-block;
			  border: 0 solid #0070ba;
			  border-bottom: 3px solid #257eba;
			  font-size: 20px;
			  color: #fff;
			  font-weight: 600;
			  border-radius: 1px !important;
			  box-sizing: border-box;
			  width: 100%;
			  cursor: pointer;
			  margin: 0 0 20px !important;
			  position: relative;
			  overflow: hidden;
			  text-align: center;
			  padding: 16px 15px;
			}
	
			.radio {
			  &:checked + label {
				background: #3ec1ff;
				border: solid #3ec1ff;
				border-width: 0 0 3px;
			  }
			}
	
			input.radio {
			  position: absolute;
			  visibility: hidden;
			}
	
			.banknames-radio {
			  background: #2f9de8;
			  box-sizing: border-box;
			  height: 60px;
			  position: relative;
			  cursor: pointer;
			  outline: none;
			  padding: 0;
			  line-height: 60px;
			  border-radius: 4px;
			  width: 100%;
			  clear: none;
			  color: #fff;
			  font-weight: 400;
			  display: block;
			  font-size: 18px;
			  text-align: center;
			  margin: 0 0 5px;
			}
	
			.banknames-radio {
			  &:checked + label {
				background: #3ec1ff;
			  }
			}
	
			input.banknames-radio {
			  position: absolute;
			  visibility: hidden;
			}
		  }
	
		  .form-select {
			font-size: 16px;
			border: 2px solid #ddd !important;
			height: 50px !important;
			border-radius: 1px;
			color: #777;
		  }
	
		  .form-control {
			font-size: 16px;
			border: 2px solid #ddd !important;
			height: 50px !important;
			border-radius: 1px;
			margin-bottom: 10px !important;
		  }
	
		  fieldset {
			border: 1px solid #2f9de8;
			padding: 10px;
			border-radius: 0;
			margin-bottom: 15px;
	
			legend {
			  padding: 0 10px;
			  font-size: 20px;
			  width: auto;
			  float: none;
			  margin: 0px;
			}
		  }
	
		  .manual-entry-btn {
			font-size: 20px;
			color: #636363;
		  }
	
		  .not_sure {
			font-size: 18px;
			font-weight: 600;
			color: #777;
			cursor: pointer;
		  }
	
		  .back-btn {
			color: #0d6efd;
			cursor: pointer;
			font-size: 20px;
			text-decoration: underline;
		  }
		}
	
		.ssl_secure {
		  img {
			height: 30px;
		  }
		}
		.privacyico {
		  height: 50px !important;
		}
	  }
	
	  .content-section {
		background-color: #fff;
		padding: 15px 0px;
		color: #777;
	
		img {
		  margin: 40px 0px;
		}
	
		h4 {
		  font-size: 24px;
		  margin-bottom: 15px;
		}
	  }
	
	  .accordion-section {
		background-color: #fff;
		padding: 15px 0px;
	
		h4 {
		  font-size: 24px;
		}
	
		.accordion {
		  .accordion-item {
			.accordion-header {
			  .accordion-button {
				background-color: #f9f9fa;
				color: #007bff !important;
				font-size: 20px;
			  }
			}
	
			.accordion-body {
			  p {
				font-size: 14px !important;
			  }
			}
		  }
		}
	  }
	  
	  .slideone-modal {
		.modal-content {
		  border: solid #2f9de8 !important;
		  border-width: 5px 5px 25px !important;
		  border-radius: 0 !important;
	
		  .modal-body {
			p {
			  font-size: 20px;
			  margin: 0 !important;
			  padding: 0 0 20px;
			  color: #777;
			}
	
			.modal-bttn {
			  background: #2f9de8;
			  font-size: 20px;
			  color: #fff;
			  border: none;
			  border-bottom: 3px solid #257eba;
			  border-radius: 3px !important;
			}
		  }
		}
	  }
	
	  .analyz-modal {
		.modal-content {
		  border: solid #2f9de8 !important;
		  border-width: 5px 5px 25px !important;
		  border-radius: 0 !important;
	
		  .modal-body {
			p {
			  font-size: 14px;
			  margin: 0 !important;
			}
		  }
		}
	  }
	
	  $borderWidth: 7px;
	  $animationTime: 0.5s;
	  $border-color-default: #eaeaea;
	  $border-color-fill: #2f9de8;
	  $size: 120px;
	  $howManySteps: 100;
	
	  .progress {
		width: $size;
		height: $size !important;
		line-height: $size;
		background: none;
		margin: 0 auto;
		box-shadow: none;
		position: relative;
		background: none !important;
		margin-bottom: 30px;
		&:after {
		  content: "";
		  width: 100%;
		  height: 100%;
		  border-radius: 50%;
		  border: $borderWidth solid $border-color-default;
		  position: absolute;
		  top: 0;
		  left: 0;
		  background-color: #fff;
		}
		> span {
		  width: 50%;
		  height: 100%;
		  overflow: hidden;
		  position: absolute;
		  top: 0;
		  z-index: 1;
		}
		.perce {
		  font-size: 28px !important;
		  font-weight: 100;
		}
	
		.progress-left {
		  left: 0;
		}
		.progress-bar {
		  width: 100%;
		  height: 100%;
		  background: none;
		  border-width: $borderWidth;
		  border-style: solid;
		  position: absolute;
		  top: 0;
		  border-color: $border-color-fill;
		}
		.progress-left .progress-bar {
		  left: 100%;
		  border-top-right-radius: ($size/2);
		  border-bottom-right-radius: ($size/2);
		  border-left: 0;
		  -webkit-transform-origin: center left;
		  transform-origin: center left;
		}
		.progress-right {
		  right: 0;
		  .progress-bar {
			left: -100%;
			border-top-left-radius: ($size/2);
			border-bottom-left-radius: ($size/2);
			border-right: 0;
			-webkit-transform-origin: center right;
			transform-origin: center right;
		  }
		}
		.progress-value {
		  display: flex;
		  border-radius: 50%;
		  font-size: 1rem;
		  text-align: center;
		  line-height: 20px;
		  align-items: center;
		  justify-content: center;
		  height: 100%;
		  width: 100%;
		  font-weight: 300;
		  z-index: 9;
		  span {
			font-size: 12px;
			text-transform: uppercase;
		  }
		}
	  }
	
	  @for $i from 1 through $howManySteps {
		$stepName: ($i * (100 / $howManySteps));
	
		@if $i <= ($howManySteps/2) {
		  .progress[data-percentage="#{$stepName}"] {
			.progress-right .progress-bar {
			  animation: loading-#{$i} $animationTime linear forwards;
			}
			.progress-left .progress-bar {
			  animation: 0;
			}
		  }
		}
	
		@if $i > ($howManySteps/2) {
		  .progress[data-percentage="#{$stepName}"] {
			.progress-right .progress-bar {
			 animation: loading-#{($howManySteps/2)}
				$animationTime
			   linear
				forwards; //set the animation to longest animation
			}
			.progress-left .progress-bar {
			  animation: loading-#{$i -
				($howManySteps/2)}
				$animationTime
				linear
				forwards
				$animationTime;
			}
		  }
		}
	  }
	
	  @for $i from 1 through ($howManySteps/2) {
		$degrees: (180/ ($howManySteps/2));
		$degrees: ($degrees * $i);
		@keyframes loading-#{$i} {
		  0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		  }
		  100% {
			-webkit-transform: rotate($degrees);
			transform: rotate(#{$degrees}deg);
		  }
		}
	  }
	.footer-document{
		cursor :pointer;
		color: #0d6efd;
		text-decoration: underline;
	}
	.analyseClass .modal-content {
		border: solid #2f9de8 !important;
		border-width: 5px 5px 25px !important;
		border-radius: 0 !important;
	}
	.analyseClass {
		background: rgba(0,0,0,.6)!important;
		margin-top: auto;
	}
    .modal-open .modal {
		overflow-x: hidden;
		overflow-y: auto;
		
	}
	.fadetoggle {
		transition: opacity 0.15s linear;
	}
	.error_msg {
		color: #ffffff;
		width: 100%;
		float: left;
		background: #f00;
		font-size: 13px;
		padding: 0px 5px;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		margin-bottom: 10px;
	}

    .hide{
		display: none;
	}
	.show{
		display: block;
	}
header{
    background-color: #257eba;

    .top_content{
        background-color: #f50b0b;
        padding: 5px;
        margin-bottom: 15px;

        span{
            color: #fff;
            font-size: 15px;
            font-weight: 600;

            img{
                height: 20px;
            }
        }
    }
}

.form-section{


    .next-bttn {
        background: #2f9de8;
        color: #fff;
        font-weight: 400;
        font-size: 26px;
        padding: 15px 10px;
        width: 100%;
        cursor: pointer;
        border: 0;
        border-radius: 0!important;

        &:hover{
            color: #fff!important;
            background: #69a2d7;
        }
    }

}


.TMS_questi{
	padding: 0px;
	margin: 0px;
	&::before{
		z-index: -1;
		opacity: 0.4;
		content: "";
		position: fixed;
		height: 100%;
		width: 100%;
		background: #3ec1ff54!important;
		background: -webkit-gradient(left top,left bottom,color-stop(0,#3ec1ff54),color-stop(24%,#fff),color-stop(49%,#fff),color-stop(69%,#fff),color-stop(100%,#3ec1ff54))!important;
		background: linear-gradient(
		180deg,#3ec1ff54 0,#fff 24%,#fff 49%,#fff 69%,#3ec1ff54)!important;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3ec1ff54",endColorstr="#5cc0ad",GradientType=0);
	}
.radio-box {
	h4{
		color: rgba(9, 62, 177, 0.68);
	}
    .styl_i{
      color: #45474a;
      font-style: italic;
	  }
	  .styl2_i{
		color: #45474a;
		font-style: italic;
		}
  }

}

footer {
	padding: 25px 0;
	background: #333;
  
	p {
	  color: #beb8b8;
	  font-size: 14px;
	}
  
	ul {
	  padding: 0;
	  margin-top: 15px;
  
	  li {
		list-style: none;
		border-right: 1px solid #beb8b8;
		padding: 0 10px;
		display: inline;
  
		&:last-child {
		  border-right: none;
		}
  
		&:first-child {
		  padding-left: 0px;
		}
	  }
	}
  }
  @media (max-width: 767px) {
	.TMS_BRN_V3_2_1B {
	  header {
		.logo {
		  height: 50px;
		}
  
		h1 {
		  font-size: 28px;
		  padding-top: 15px;
		}
  
		h2 {
		  font-size: 21px;
		}
	  }
  
	  .content-section {
		.bordr {
		  border-bottom: 2px dashed #257eba;
		  border-top: 2px dashed #257eba;
		}
	  }
  
	  .accordion-section {
		background-color: #69a2d7;
		padding: 40px 0 30px;
		color: #fff;
  
		.accordion {
		  .accordion-item {
			.accordion-body {
			  p {
				color: #000;
			  }
			}
		  }
		}
	  }
  
	  footer {
		p {
		  color: #fff;
		  font-size: 13px;
		  text-align: center;
		}
  
		ul {
		  li {
			display: block;
			padding: 10px 0px;
			border-right: none;
			font-size: 13px;
			border-top: 1px dotted #94a2a6;
  
			a {
			  color: #fff;
			  text-decoration: none;
			}
		  }
		}
	  }
	}
  
	.questionspart {
	  &::after {
		background-size: 45%;
	  }
	}
	.modal {
	  padding-top: 15%;
	}
  }
  
}