.TMS_sign{
    
    $background_color_1: #ffffff;
    $background_color_2: #0070ba;
    $background_color_3: #89b0eb;
    $background_color_4: rgba(9, 62, 177, 0.68);
    $background_color_5: #c32d2d;
    $background_color_6: rgba(21, 54, 111, 0.45);

    $color_1: #ffffff;
    $color_2: #000000;
    $color_3: rgba(9, 62, 177, 0.68);
    $color_4: #0070ba;

    $font_family_1: Titillium Web, sans-serif;
    .signature-section {
        font-family: $font_family_1;
        padding: 60px 0 !important;
        height: auto;
        .sign-question {
          padding: 0px;
          h2 {
            text-align: center;
            font-size: 20px;
            color: #fff;
            padding: 20px 15px;
            font-weight: 600;
            background: #257eba;
            margin-bottom: 10px;
          }
          ul {
            margin: 0;
            padding: 0 40px;
            li {
              background: url(../../img/download.png) no-repeat 0;
              list-style: none;
              padding: 5px 0 5px 35px;
              margin-bottom: 0;
              font-size: 17px;
              text-align: left !important;
            }
          }
        }
        .sign-div {
          .signature_canvaseWrap {
            .signature-pad {
              display: block;
              margin: 0 auto;
              // width: 100%;
              background: url(../../img/sign-bg.png) no-repeat #fff 49% 67px !important;
              padding: 0.375rem 0.75rem;
              font-size: 1rem;
              line-height: 1.5;
              color: #495057;
              background-color: #fff;
              background-clip: padding-box;
              border: 1px solid #ced4da;
              border-radius: 0.25rem;
              transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            }
          }
          .form-check-input {
            background-color: #fff;
            border-color: #999999;
          }
          .form-check-input:checked {
            background-color: #0d6efd;
            border-color: #0d6efd;
          }
          label {
            color: #212529 !important;
            font-size: 14px;
          }
          .btn-clear {
            background-color: #fb0404;
            font-size: 15px;
            color: #fff;
            font-weight: 400;
            font-size: 20px !important;
            border-radius: 4px;
            padding: 9px 15px;
            border: 0;
            width: auto;
            cursor: pointer;
            margin-bottom: 10px;
            text-transform: uppercase;
          }
          .btn-submit {
            background-color: #309814;
            font-size: 15px;
            color: #fff;
            font-weight: 400;
            font-size: 20px !important;
            border-radius: 4px;
            padding: 9px 50px;
            border: 0;
            width: auto;
            cursor: pointer;
            margin-bottom: 10px;
            text-transform: uppercase;
            float: right;
          }
          .anim_ylw {
            -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
            -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
            -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
            animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
            box-shadow: 0 0 0 0 #f1ee05;
      
            @-webkit-keyframes pulse {
              to {
                box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
              }
            }
      
            @-moz-keyframes pulse {
              to {
                box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
              }
            }
      
            @-ms-keyframes pulse {
              to {
                box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
              }
            }
      
            @keyframes pulse {
              to {
                box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
              }
            }
          }
        }
      }
    .sign_msg{
        color: #fff;
        background: #f00;
        width: 100%;
        padding: 2px 5px;
        text-align: center;
        border-radius: 4px;
        font-size: 13px;
        margin-top: 10px;
      }
      
      .sign {
        background: #257eba;
      }
      .logo-part {
        img {
          margin: 0;
          height: 60px;
        }
      }
      .gif1 {
        font-family: $font_family_1;
        img {
          margin: 50px 0 10px;
          height: 100px;
        }
        h1 {
          padding: 10px 0;
          color: #fb1414;
        }
        .unqualifiedty .gradient_sec .gif1 h6 {
          font-weight: 600;
          font-size: 18px;
        }
      }
    footer {
        padding: 25px 0;
        background: #333;
      
        p {
          color: #beb8b8;
          font-size: 14px;
        }
      
        ul {
          padding: 0;
          margin-top: 15px;
      
          li {
            list-style: none;
            border-right: 1px solid #beb8b8;
            padding: 0 10px;
            display: inline;
      
            &:last-child {
              border-right: none;
            }
      
            &:first-child {
              padding-left: 0px;
            }
          }
        }
      }
}