.Idupload {
	$background_color_1: #ffffff;
	$background_color_2: #0070ba;
	$background_color_3: #89b0eb;
	$background_color_4: rgba(9, 62, 177, 0.68);
	$background_color_5: #c32d2d;
	$background_color_6: rgba(21, 54, 111, 0.45);

	$color_1: #ffffff;
	$color_2: #000000;
	$color_3: rgba(9, 62, 177, 0.68);
	$color_4: #0070ba;

	$font_family_1: Titillium Web, sans-serif;

	.btn-camera {
		background: #0070ba !important;
		color: #fff !important;
		padding: 12px 10px !important;
		border: 0px;
		-moz-border-radius: 5px;
		-webkit-border-radius: 5px;
		border-radius: 5px;
		width: 100% !important;
		margin-bottom: 15px;
	}
	.custom-file-button {
		input[type="file"] {
			margin-left: -2px !important;
	
			&::-webkit-file-upload-button {
				display: none;
			}
			&::file-selector-button {
				display: none;
			}
		}
	
		.form-control{
			padding: 9px 10px;
			font-size: 15px;
			border-radius: 0.325rem;
		}
		.input-group-text{
			background-color: #0070ba;
			color: #fff;
			border-radius: 0.325rem;
		}
	}
	.btn-next {
		background: #257eba;
		color: #fff;
		font-weight: 400;
		font-size: 20px;
		padding: 15px 10px;
		width: 100%;
		cursor: pointer;
		border: 0;
		border-radius: 5px !important;
		margin: 15px 0;
	}
	.radio-box h4{
		color: rgba(9,62,177,0.68);
	}
	.questionspart {
		font-family: $font_family_1;
		&::before {
		  z-index: -1;
		  content: "";
		  position: fixed;
		  height: 200px;
		  width: 100%;
		  bottom: 44px;
		  left: 0;
		  background-position: left bottom !important;
		  background-repeat: no-repeat !important;
		  background-size: 72%;
		  opacity: 0.3;
		}
		&::after {
		  z-index: -1;
		  content: "";
		  position: fixed;
		  height: 180px;
		  width: 100%;
		  top: 2%;
		  background-image: url(../img/logo-dark.png) !important;
		  background-position: center !important;
		  background-repeat: no-repeat !important;
		  background-size: 20%;
		  opacity: 0.08;
		}
	  
		.btn-primary {
		  background: $background_color_2 !important;
		  border-color: $color_3 !important;
		}
	  
		.radio-box {
		  margin: 45% 0;
		  display: inline-block;
		  width: 100%;
		  h4 {
			font-size: 24px;
			font-weight: 400;
			margin-bottom: 20px;
			strong {
			  font-weight: 700;
			}
		  }
		  h5 {
			font-size: 16px;
			font-weight: 400;
			margin-bottom: 20px;
		  }
	  
		  label.radioa {
			border: 2px solid $color_3;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			border-radius: 4px;
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			height: 48px;
			position: relative;
			cursor: pointer;
			outline: none;
			margin: 0;
			padding: 0px 0px 0px 50px;
			line-height: 44px;
			margin-bottom: 10px;
			width: 100%;
			float: left;
			text-align: left;
			clear: none;
			color: $color_3;
			font-weight: normal;
			display: block;
			font-size: 15px;
	  
			span {
			  position: absolute;
			  left: 2px;
			  color: #fff;
			  font-weight: 400;
			  height: 40px;
			  line-height: 40px;
			  padding: 0px 14px;
			  margin: 2px 0px;
			  background-color: $background_color_2;
			  border-radius: 5px;
			}
		  }
		  input:checked + label {
			color: #fff;
			background: rgba(9, 131, 168, 0.68) url(../img/checked.png) no-repeat
			  6px !important;
			color: #fff !important;
			border: 2px solid $color_3;
		  }
		}
	  
		label.radio {
		  &:active {
			background-color: $background_color_2;
			border-color: $color_3;
			color: $color_1;
		  }
		}
	  
		input.radio {
		  position: absolute;
		  visibility: hidden;
		}
		.custom-checkbox {
		  display: none;
		}
	  
		.cstfrm {
		  label {
			background: hsla(0, 0%, 92.5%, 0.54) url(../img/unchecked.png)
			  no-repeat 6px;
		  }
		  label.radioa {
			width: 49%;
			margin: 2px 1px 10px;
			line-height: 44px;
			padding: 0px 0px 0px 34px;
		  }
		}
		.custom-checkbox:checked + label {
		  background: rgba(9, 131, 168, 0.68) url(../img/checked.png) no-repeat
			6px !important;
		  color: #fff;
		  border: 2px solid $color_3;
		}
	  
		.btn-div {
		  margin: 50% 0 300px;
		  .btn-submit {
			background: $background_color_2;
			padding: 10px;
			color: $color_1;
			font-size: 17px;
			font-weight: 500;
			width: 100%;
			border: 0px;
			border-radius: 5px;
		  }
		}
	  }
}